<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card class="mt-2">
        <b-row class="mb-2">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" v-if="!showSurveyDetail" class="bg-primary text-white align-middle" style="font-size: 1.2em;">
                  Create Questionnaire
                </th>
                <th scope="col" v-else class="bg-primary text-white align-middle">
                  {{ questionnaire.title }}
                </th>

                <th v-if="showSurveyDetail" scope="col" class="bg-primary text-white d-flex justify-content-end align-middle">
                  <b-button
                    v-b-tooltip.hover
                    :title="questions.length == 0 ? 'Please add at least one question to the table' : ''"
                    :disabled="questions.length == 0"
                    variant="success"
                    class="animate__animated animate__fadeIn m-0 "
                    style="background-color:white;color:#003b49"
                    size="sm"
                    @click="saveButton()"
                  >
                    Update Questionnaire
                  </b-button>
                </th>
              </tr>
            </thead>
          </table>
        </b-row>

        <validation-observer ref="questionaireRules1" v-if="!showSurveyDetail" :class="!showSurveyDetail ? 'animate__animated animate__fadeInUp' : ' animate__animated animate__fadeOutUp'">
          <b-row>
            <!-- Create Questionnaire -->
            <b-col cols="12" xl="7" md="7" sm="12">
              <b-form-group label="Questionnaire Title" label-for="questionnaire">
                <validation-provider #default="{ errors }" name="questionnaire">
                  <b-input-group>
                    <b-form-input id="questionnaire" v-model.lazy="questionnaire.title" type="text" placeholder="Questionnaire" :state="questionnaireNameState" aria-describedby="input-live-feedback"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-form-invalid-feedback id="input-live-feedback">
                      Enter at least 5 letters
                    </b-form-invalid-feedback>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="3" md="3" sm="12">
              <b-form-group label="Grade Levels" label-for="gradeLeves" v-b-tooltip.hover :title="gradeLevels.length == 0 ? 'Please add grade level' : ''">
                <validation-provider #default="{ errors }" name="gradeLeves">
                  <v-select id="gradeLeves" v-model="selectedGradeLevels" multiple label="title" :reduce="(val) => val" :options="gradeLevels" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="2" md="2" sm="12" class="mt-2">
              <b-button v-if="!showSurveyDetail" block :variant="questionnaire.title == null || selectedGradeLevels.length == 0 ? 'outline-warning' : 'warning'" :disabled="questionnaire.title == null || selectedGradeLevels.length == 0" @click="createSurvey()">Create Questionnaire</b-button>
              <b-button v-else block variant="outline-warning" @click="editSurvey()" :disabled="questions.length == 0 || selectedGradeLevels.length == 0">Update Questionnaire</b-button>
            </b-col>
          </b-row>
        </validation-observer>

        <!-- <b-row>
          <b-col>
            <hr />
          </b-col>
        </b-row> -->

        <validation-observer ref="questionaireRules2">
          <b-row v-if="showSurveyDetail" class="animate__animated animate__fadeInUp">
            <b-col cols="12" xl="12" md="12" sm="12">
              <b-row>
                <!-- Groups -->
                <b-col cols="12" xl="9" md="9" sm="12">
                  <b-form-group label="Groups" label-for="groups">
                    <validation-provider #default="{ errors }" name="group">
                      <v-select
                        id="group"
                        v-model="questionData.group"
                        :taggable="true"
                        :autoscroll="true"
                        maxHeight="50"
                        :create-option="(temp) => ({ id: 0, groupName: temp })"
                        :loading="groupSelectBoxLoading"
                        @option:created="createNewGroupValue"
                        @option:selected="selectedGroupValue"
                        :reduce="(val) => val"
                        :options="groupData"
                        label="groupName"
                        :clearable="true"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Question Type -->
                <b-col cols="12" sm="12" md="3" xl="3">
                  <b-form-group label="Question Type" label-for="questionType">
                    <validation-provider name="type" #default="{ errors }">
                      <v-select id="type" v-model="questionData.type" :options="questionTypes" :reduce="(val) => val" :filterable="false" label="name" :clearable="false" input-id="QuestionType" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <!-- Add Question -->
                <b-col cols="12" xl="10" md="10" sm="12" class="pr-0">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="title">
                      <b-input-group>
                        <b-form-input id="title" v-model.lazy="questionData.title" type="text" placeholder="Add Question" :state="titleState" aria-describedby="input-live-feedback"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <b-form-invalid-feedback id="input-live-feedback">
                          Enter at least 5 letters
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="2" md="2" sm="12" class="pl-1">
                  <b-button block variant="outline-success" :disabled="questionData.title === '' ? true : false" @click="addQuestion()">Add New Question +</b-button>
                </b-col>
              </b-row>

              <hr />
              <b-row align-h="between">
                <b-col cols="6">
                  <b-form-group label="Search in Table" class="mb-40">
                    <b-input-group>
                      <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button @click="filter = ''" variant="warning">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- set colour -->
                <b-col cols="6" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label="Select Chart Colour" class="mb-40">
                    <ColourPicker id="chartColour" ref="refChartColour" v-model="chartColour" :value="chartColour" picker="compact" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                :items="questions"
                :small="true"
                responsive
                :bordered="true"
                :sticky-header="true"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                style="padding-bottom:30px"
                class="position-relative "
                head-variant="dark"
                :filter="filter"
                filter-debounce="250"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="column"
                striped
                hover
                :current-page="currentPage"
                :per-page="perPage"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(questionType)="data">
                  <!-- <template v-if="edit != item.id">{{ value }}</template> -->
                  <!-- <b-form-input v-model="data.item.group" /> -->
                  <span class="font-weight-bold">
                    {{ data.item.type.status === 'A' ? 'Answer' : 'Comment' }}
                  </span>
                </template>
                <template v-slot:cell(groups)="data">
                  <!-- <template v-if="edit != item.id">{{ value }}</template> -->
                  <!-- <b-form-input v-model="data.item.group" /> -->
                  <span class="font-weight-bold">
                    {{ data.item.group.groupName }}
                  </span>
                </template>
                <template v-slot:cell()="data">
                  <!-- <template v-if="edit != item.id">{{ value }}</template> -->
                  <!-- <b-form-input v-model="data.item.question" /> -->
                  <span class="font-weight-bold">
                    {{ data.item.title }}
                  </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <b-dropdown-item @click="editQuestion(data.item)">
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Edit Question </span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="deleteQuestion(data.item.questionId)">
                      <feather-icon icon="DeleteIcon" />
                      <span class="align-middle ml-50">Delete Question</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>

              <div class="mx-2 mb-2">
                <b-row>
                  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                  </b-col>

                  <!-- Pagination -->
                  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
        <b-modal id="modal-prevent-closing" ref="modal" title="Edit Question" size="lg" v-model="modalShow" @hidden="resetModal" @ok="handleOk">
          <validation-observer ref="editQuestionRules">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-col cols="12" sm="12" md="12" xl="12">
                <b-form-group label="Question Type" label-for="EditQuestionType">
                  <validation-provider name="EditQuestionType" #default="{ errors }">
                    <v-select id="EditQuestionType" v-model="editQuestionData.type" :options="questionTypes" :reduce="(val) => val" :filterable="false" label="name" :clearable="false" input-id="EditQuestionType" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="12" md="12" sm="12">
                <b-form-group label="Group" label-for="editGroup">
                  <validation-provider #default="{ errors }" name="editGroup">
                    <v-select
                      id="editGroup"
                      v-model="editQuestionData.group"
                      :taggable="true"
                      :autoscroll="true"
                      maxHeight="50"
                      :create-option="(temp) => ({ id: 0, groupName: temp })"
                      :loading="groupSelectBoxLoading"
                      @option:created="editGroupValue"
                      @option:selected="selectedGroupValue"
                      :reduce="(val) => val"
                      :options="groupData"
                      label="groupName"
                      :clearable="true"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="12" md="12" sm="12">
                <b-form-group label="Question" label-for="editQuestion">
                  <validation-provider #default="{ errors }" name="editQuestion">
                    <b-input-group>
                      <b-form-textarea id="editQuestion" v-model.lazy="editQuestionData.title" type="text" rows="3" placeholder="Edit Question" :state="editTitleState" aria-describedby="input-live-feedback-edit" no-resize></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <b-form-invalid-feedback id="input-live-feedback-edit">
                        Enter at least 5 letters
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </form>
          </validation-observer>
        </b-modal>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BPagination, BFormRating, BIcon, VBTooltip } from 'bootstrap-vue';
import settingsStoreModule from '../../settingsStoreModule';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import axiosIns from '@/libs/axios';
import 'animate.css';
import ColourPicker from 'vue-colour-picker';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BIcon,
    BFormRating,
    vSelect,
    //validation
    ValidationProvider,
    ValidationObserver,
    ColourPicker,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  mounted() {
    this.getGroups();
    this.getGradeLevels();
  },
  updated() {
    // we only want it to run once for start colour picker
    if (this.$refs.refChartColour) {
      if (this.colourCount == 0) {
        this.colourCount = 1;
        this.$refs.refChartColour.colorValue = '#009CE0';
      }
    }
  },
  data() {
    return {
      dir: 'ltr',
      gradeLevels: [],
      selectedGradeLevels: [],
      chartColour: '#009CE0',
      questionnaire: {
        id: 0,
        title: null,
      },
      showSurveyDetail: false,
      groupSelectBoxLoading: false,
      questionData: {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: null,
      },
      editQuestionData: {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: null,
      },
      groupData: [],
      questionTypes: [
        { name: 'Comment', status: 'B' },
        { name: 'Answer', status: 'A' },
      ],
      loading: false,
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      modalShow: false,
      perPage: 10,
      questions: [],
      colourCount: 0,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      sortBy: 'question',
      sortDesc: false,
      totalRows: 0,
      column: [
        { key: 'questionType', sortable: true, label: 'Question Type' },
        { key: 'groups', sortable: true, label: 'Groups' },
        { key: 'question', sortable: true, label: 'Quesitons' },
        { key: 'actions', label: 'Actions' },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   thClass: "text-right",
        //   tdClass: "text-right pr-0",
        // },
      ],
    };
  },
  computed: {
    questionnaireNameState() {
      if (this.questionnaire.title === null) {
        return null;
      } else {
        return this.questionnaire.title.length > 5 ? true : false;
      }
    },
    titleState() {
      if (this.questionData.title === '') {
        return null;
      } else {
        return this.questionData.title.length > 5 ? true : false;
      }
    },
    editTitleState() {
      if (this.editQuestionData.title === '') {
        return null;
      } else {
        return this.editQuestionData.title.length > 5 ? true : false;
      }
    },
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    saveButton() {
      store
        .dispatch('settings/updateQuestionnaireColor', {
          id: this.questionnaire.id,
          chartColour: this.chartColour,
        })
        .then((res) => {
          this.toastMessage('Questionnaire');
          router.push({ name: 'settings-surveys' });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createSurvey() {
      if (this.questionnaire.title.length > 5) {
        store
          .dispatch('settings/createNewSurvey', {
            title: this.questionnaire.title.trim(),
            gradeLevels: this.selectedGradeLevels,
          })
          .then((res) => {
            if (res.status === 201 && res.data.existing == 0) {
              this.showSurveyDetail = true;
              this.questionnaire.id = res.data.data.id;
              this.toastMessage('New Questionnaire');
            } else {
              this.existingToastMessage('Questionnaire');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.questionnaireNameState;
      }
    },
    editSurvey() {
      if (this.questionnaire.title.length > 5) {
        this.loading = true;
        store
          .dispatch('settings/updateSurvey', {
            id: this.questionnaire.id,
            title: this.questionnaire.title.trim(),
            gradeLevels: this.selectedGradeLevels,
          })
          .then((res) => {
            if (res.status === 200 && res.data.existing == 0) {
              this.questionnaire.id = res.data.data.id;
              this.editToastMessage('Questionnaire');
              this.loading = false;
            } else {
              this.loading = false;
              this.existingToastMessage('Questionnaire');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.questionnaireNameState;
      }
    },
    addQuestion() {
      this.$refs.questionaireRules2.validate().then((success) => {
        if (success) {
        }
      });

      if (this.questionData.group != null) {
        if (this.questionData.title !== '' && this.questionData.title.length > 5) {
          const newQuestion = {
            title: this.questionData.title,
            groupId: this.questionData.group.id,
            status: this.questionData.type.status ? this.questionData.type.status : 'A',
          };
          store
            .dispatch('settings/addNewQuestionItem', newQuestion)
            .then((res) => {
              if (res.status === 201) {
                this.getGroups();
                this.clearQuestionData();
                this.toastMessage('New Question');
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.selectGroupToastMessage();
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    createNewGroupValue(val) {
      this.groupSelectBoxLoading = true;

      if (this.groupData.find((x) => x.groupName.toUpperCase() == val.groupName.toUpperCase()) == undefined) {
        store
          .dispatch('settings/addNewGroupItem', {
            groupName: val.groupName.trim(),
            surveyId: this.questionnaire.id,
          })
          .then((response) => {
            if (response.status == 201) {
              this.questionData.group = response.data;
              this.getGroups();
              this.toastMessage('New Group');
              this.groupSelectBoxLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.groupSelectBoxLoading = false;
      }
    },
    editGroupValue(val) {
      this.groupSelectBoxLoading = true;

      if (this.groupData.find((x) => x.groupName.toUpperCase() == val.groupName.toUpperCase()) == undefined) {
        store
          .dispatch('settings/addNewGroupItem', {
            groupName: val.groupName.trim(),
            surveyId: this.questionnaire.id,
          })
          .then((response) => {
            if (response.status == 201) {
              //this.groupData.unshift(response.data);
              this.questionData.group = {};
              this.getGroups();
              this.toastMessage('New Group');
              this.groupSelectBoxLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.groupSelectBoxLoading = false;
      }
    },
    editQuestion(question) {
      this.modalShow = true;
      this.editQuestionData = {
        id: question.questionId,
        title: question.title,
        group: question.group,
        type: question.type.status === 'A' ? { name: 'Answer', status: 'A' } : { name: 'Comment', status: 'B' },
      };
    },
    getGradeLevels() {
      store
        .dispatch('settings/fetchGradeLevels', [])
        .then((res) => {
          this.gradeLevels = [];
          res.data.forEach((gl) => {
            this.gradeLevels.unshift({
              id: gl.id,
              title: gl.title,
              status: gl.status,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGroups() {
      this.groupSelectBoxLoading = true;
      store
        .dispatch('settings/fetchGroups', [])
        .then((res) => {
          this.questions = [];
          this.groupData = [];
          res.data.forEach((group) => {
            if (group.surveyId === this.questionnaire.id) {
              this.groupData.unshift({
                id: group.id,
                groupName: group.groupName,
                status: group.status,
              });
              if (group != null && group.questions.length > 0) {
                group.questions.forEach((data) => {
                  this.questions.unshift({
                    id: group.id,
                    questionId: data.id,
                    type: { status: data.status },
                    group: { id: group.id, groupName: group.groupName },
                    title: data.title,
                  });
                });
              }
              this.totalRows = this.questions.length;
            }
          });
          this.groupSelectBoxLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedGroupValue(val) {
      //   console.log(val);
    },
    deleteQuestion(id) {
      const self = this;
      this.$swal({
        title: 'Are you sure you want to delete the question?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch('settings/deleteQuestion', id)
            .then((res) => {
              self.getGroups();
              self.$swal.fire('Deleted!', 'Question has been deleted.', 'success');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.$refs.editQuestionRules.validate().then((success) => {
        if (success) {
          if (this.editQuestionData.title !== '') {
            this.handleSubmit();
          } else {
          }
        }
      });
    },
    handleSubmit() {
      const editQuestionData = {
        id: this.editQuestionData.id,
        title: this.editQuestionData.title,
        groupId: this.editQuestionData.group.id,
        status: this.editQuestionData.type.status,
      };
      store
        .dispatch('settings/editQuestion', editQuestionData)
        .then((res) => {
          this.getGroups();
          this.resetModal();
          this.modalShow = false;
          this.editToastMessage('Question');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetModal() {
      this.editQuestionData = {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: {},
      };
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Added Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    editToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Updated Successful',
          icon: 'CheckIcon',
          variant: 'success',
        },
      });
    },
    existingToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Warning',
          text: 'This ' + val + ' name is existing',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },
    selectGroupToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Warning',
          text: 'Please select group',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },

    clearQuestionData() {
      this.questionData = {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.color-input {
  width: 80% !important;
  margin-right: 2px !important;
  height: 38px;
  border: 1px solid #d8d6de;
}
.current-color {
  height: 38px !important;
}
</style>
